@charset "UTF-8";

// This file is compiled into print.css

@import 'site-settings';

@import 'bourbon';
@import 'neat';










.sidebar {
  display: none;
}

.container {
  width: $max-width;
}
